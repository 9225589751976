import { jsx as o, Fragment as m } from "react/jsx-runtime";
import "@ionic/react";
import { d as g, f as p } from "../../../../../AuthProvider-2B-MIORJ.js";
import "@capacitor/core";
import "react";
import "../../../../../react-paginate-Lh5zQzpz.js";
function f(n) {
  const {
    subDaysWorked: i,
    score: r,
    isSwingHero: s,
    isHourly: e,
    onClick: t
  } = n, l = i < 5 || r === void 0;
  return /* @__PURE__ */ o(m, {
    children: l ? /* @__PURE__ */ o(g, {
      subDaysWorked: i,
      isHourly: e,
      onClick: t
    }) : /* @__PURE__ */ o(p, {
      score: r,
      isSwingHero: s,
      isHourly: e,
      onClick: t
    })
  });
}
export {
  f as SwingHero
};
