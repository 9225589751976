import { jsxs as i, jsx as n } from "react/jsx-runtime";
import "@ionic/react";
import "../../../../../AuthProvider-2B-MIORJ.js";
import "@capacitor/core";
import "react";
import "../../../../../react-paginate-Lh5zQzpz.js";
import { ButtonEdit as r } from "../ButtonEdit/ButtonEdit.js";
import '../../../../../css/Section.css';const c = "_section_fjl6j_1", l = {
  section: c
};
function x(e) {
  const {
    children: o,
    title: s,
    onEditClick: t
  } = e;
  return /* @__PURE__ */ i("div", {
    className: l.section,
    children: [/* @__PURE__ */ i("div", {
      style: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 24
      },
      children: [/* @__PURE__ */ n("h2", {
        style: {
          margin: 0,
          fontSize: "var(--swing-font-xl)"
        },
        children: s
      }), t && /* @__PURE__ */ n(r, {
        onClick: () => t()
      })]
    }), o]
  });
}
export {
  x as Section
};
