import { IonPage } from "@ionic/react";
import { generatePath } from "react-router";
import { LoadingInterstitial } from "swing-components";

import { useAuth } from "~components";
import { ValuesOf } from "~utils";
import { Onboarding } from "../onboarding";
import { PostOnboarding } from "./PostOnboarding";

/********* Constants **********/
export const AUTH_URLS = {
  root: "/",
  requests: "/requests",
  request: "/requests/:requestId",
  myRequests: "/my-requests",
  myRequestsDetails: "/my-requests/:requestId",
  profile: "/profile",
  educationLanding: "/profile/education",
  educationNew: "/profile/education/new",
  educationEdit: "/profile/education/:educationId",
  workExperienceLanding: "/profile/work-experience",
  workExperienceNew: "/profile/work-experience/new",
  workExperienceEdit: "/profile/work-experience/:workExperienceId",
  more: "/more",
  notifications: "/more/notifications",
  payment: "/more/payment",
  myEarnings: "/more/payment/my-earnings",
  paymentSetup: "/more/payment/payment-setup",
  help: "/more/help",
  settings: "/more/settings",
  locationSettings: "/more/location-settings",
  referralProgram: "/more/referral",
  myDocuments: "/more/my-documents",
  openingsSchoolProfile: "/requests/:requestId/school/:schoolId",
  myRequestsSchoolProfile: "/my-requests/:requestId/school/:schoolId",
  skillBuilderTasks: "/more/skill-builder/:version",
  skillBuilderTask: "/more/skill-builder/:version/:task/:step?",
} as const;

export const LEGACY_URLS = {
  local: "http://localhost:3000",
  staging: "https://staging2.aws.swingeducation.com",
  prod: "https://subs.swingeducation.com",
} as const;

/********** Component *********/
type AuthedRoutesProps = {
  /**
   * Used to expose path only for testing routes
   * TODO: @KoltonG - Wondering if one day we can get rid of this :P
   */
  testPath?: ValuesOf<typeof AUTH_URLS>;
};

export function AuthedRoutes(props: AuthedRoutesProps) {
  const { testPath } = props;

  /***** Hooks *****/
  const { userInfo } = useAuth();

  /***** Render *****/
  // Render a loading screen if the user info is not available
  if (!userInfo.onboardingStatus)
    return (
      <IonPage>
        <LoadingInterstitial />
      </IonPage>
    );

  // NOTE: The reason why the onboarding experience is not sharing the
  // `<IonTab />` component is due to non-onboarded users having restricted
  // navigation options.
  if (userInfo.onboardingStatus !== "COMPLETED") return <Onboarding />;

  // Otherwise render the authenticated experience
  return <PostOnboarding testPath={testPath} />;
}

/********** Helper Functions **********/
export function createRootUrl() {
  return generatePath(AUTH_URLS.root);
}

export function createRequestsUrl() {
  return generatePath(AUTH_URLS.requests);
}

export function createRequestUrl(requestId: string) {
  return generatePath(AUTH_URLS.request, { requestId });
}

export function createMyRequestsUrl() {
  return AUTH_URLS.myRequests;
}

export function createMyRequestsDetailsUrl(requestId: string) {
  return generatePath(AUTH_URLS.myRequestsDetails, { requestId });
}

export function createProfileUrl() {
  return generatePath(AUTH_URLS.profile);
}

export function createMoreUrl() {
  return AUTH_URLS.more;
}

export function createPaymentUrl() {
  return generatePath(AUTH_URLS.payment);
}

export function createPaymentSetupUrl() {
  return generatePath(AUTH_URLS.paymentSetup);
}

export function createMyEarningsUrl() {
  return generatePath(AUTH_URLS.myEarnings);
}

export function createHelpUrl() {
  return generatePath(AUTH_URLS.help);
}

export function createSettingsUrl() {
  return generatePath(AUTH_URLS.settings);
}

export function createLocationSettingsUrl() {
  return generatePath(AUTH_URLS.locationSettings);
}

export function createEducationLandingUrl() {
  return generatePath(AUTH_URLS.educationLanding);
}

export function createEducationNewUrl() {
  return generatePath(AUTH_URLS.educationNew);
}

export function createEducationEditUrl(educationId: string) {
  return generatePath(AUTH_URLS.educationEdit, { educationId });
}

export function createWorkExperienceLandingUrl() {
  return generatePath(AUTH_URLS.workExperienceLanding);
}

export function createWorkExperienceNewUrl() {
  return generatePath(AUTH_URLS.workExperienceNew);
}

export function createWorkExperienceEditUrl(workExperienceId: string) {
  return generatePath(AUTH_URLS.workExperienceEdit, { workExperienceId });
}

export function createSchoolProfileUrl(
  isFromOpenings: boolean,
  requestId: string,
  schoolId: string,
) {
  return generatePath(
    isFromOpenings ? AUTH_URLS.openingsSchoolProfile : AUTH_URLS.myRequestsSchoolProfile,
    { requestId, schoolId },
  );
}

export function createSkillBuilderTasksURL(version: string) {
  return generatePath(AUTH_URLS.skillBuilderTasks, { version });
}

export function createReferralProgramUrl() {
  return generatePath(AUTH_URLS.referralProgram);
}

// export for testing
export function isTabSelected(testPath: string | undefined, pathName: string, authUrl: string) {
  return (testPath && testPath === authUrl) || pathName.startsWith(authUrl);
}

export function createNotificationsUrl() {
  return generatePath(AUTH_URLS.notifications);
}

export function createMyDocumentsUrl() {
  return generatePath(AUTH_URLS.myDocuments);
}
