import { useHistory } from "react-router";
import { FEATURE_FLAGS } from "src/utils/feature-flags";
import { Colors, IconBlockGroup } from "swing-components";

import { Badge, ContentNavigationItem, ContentSingleColumn, useAuth } from "~components";
import {
  createHelpUrl,
  createLocationSettingsUrl,
  createMyDocumentsUrl,
  createNotificationsUrl,
  createPaymentUrl,
  createReferralProgramUrl,
  createSkillBuilderTasksURL,
} from "~pages";
import { msg } from "~utils";
import { ScorePage } from "../../ScoreTemplates/ScorePage";
import styles from "./More.module.css";

export function More() {
  const { signOut, userInfo } = useAuth();
  const history = useHistory();

  return (
    <ScorePage title={msg("PAGE_TITLE_MORE")}>
      <ContentSingleColumn>
        <div className={styles["content-wrapper"]}>
          <div style={{ marginBottom: "24px" }}>
            <IconBlockGroup title="Settings">
              {/* Notifications */}
              {/* TODO: Remove FF when feature is ready for release */}
              {FEATURE_FLAGS.NOTIFICATIONS && (
                <ContentNavigationItem
                  navigationUrl={createNotificationsUrl()}
                  icon={{ name: "Notifications" }}
                  label={msg("MORE_NOTIFICATIONS")}
                  navigationIcon="Chevron Right"
                  hasWhiteBackground={false}
                />
              )}
              {!userInfo.isHourly && (
                <ContentNavigationItem
                  navigationUrl={createPaymentUrl()}
                  icon={{ name: "Monetization" }}
                  label={msg("PAGE_TITLE_PAYMENT")}
                  navigationIcon="Chevron Right"
                  hasWhiteBackground={false}
                />
              )}
              {/* My Documents */}
              <ContentNavigationItem
                icon={{ name: "Note" }}
                label={msg("SETTINGS_MY_DOCUMENTS")}
                navigationIcon="Chevron Right"
                navigationUrl={createMyDocumentsUrl()}
                hasWhiteBackground={false}
              />
              {/* Locations Settings */}
              <ContentNavigationItem
                navigationUrl={createLocationSettingsUrl()}
                icon={{ name: "Location" }}
                label={msg("MORE_LOCATION_SETTINGS")}
                hasDivider={false}
                navigationIcon="Chevron Right"
                hasWhiteBackground={false}
              />
            </IconBlockGroup>
          </div>
          <div style={{ marginBottom: "24px" }}>
            <IconBlockGroup title="Resources">
              {/* Skill Builder Program */}
              {FEATURE_FLAGS.SKILL_BUILDER && (
                <ContentNavigationItem
                  navigationUrl={createSkillBuilderTasksURL("swing")}
                  icon={{ name: "Lesson" }}
                  label="Skill Builder"
                  navigationIcon="Chevron Right"
                  hasWhiteBackground={false}
                  badge={<Badge status="warning" text={`${msg("LABEL_NEW")}!`} />}
                />
              )}
              {/* Referral Program */}
              {FEATURE_FLAGS.REFERRAL && (
                <div className={styles["hide-on-desktop"]}>
                  <ContentNavigationItem
                    navigationUrl={createReferralProgramUrl()}
                    icon={{ name: "Group" }}
                    label={msg("MORE_REFERRAL_PROGRAM")}
                    navigationIcon="Chevron Right"
                    hasWhiteBackground={false}
                  />
                </div>
              )}
              <div className={styles["hide-on-desktop"]}>
                <ContentNavigationItem
                  navigationUrl={createHelpUrl()}
                  icon={{ name: "Help" }}
                  label={msg("MORE_HELP")}
                  navigationIcon="Chevron Right"
                  hasWhiteBackground={false}
                />
              </div>
            </IconBlockGroup>
          </div>

          {/* Conditionally hide Help and Log Out on desktop */}
          <div className={styles["hide-on-desktop"]}>
            <div style={{ marginBottom: "24px" }}>
              <IconBlockGroup>
                <ContentNavigationItem
                  icon={{ name: "Logout", color: Colors.red500 }}
                  label={msg("MORE_LOGOUT")}
                  onClick={() => signOut({ history })}
                  hasWhiteBackground={false}
                />
              </IconBlockGroup>
            </div>
          </div>
        </div>
      </ContentSingleColumn>
    </ScorePage>
  );
}
