/******************************************************************************
 * AUTOGENERATED FILE - DO NOT EDIT
 * Edit template file instead at `/etc/build.ts.tmpl`
 * To re-trigger generation, run `make init`
 ******************************************************************************/
export const BUILD_PROFILE: "local" | "staging" | "prod" = "prod";
export const BUILD_VER = "250242316";
export const BUILD_TAG = "production-250242316";
export const BUILD_SHA = "a3f16a26";
export const GOOGLE_CLIENT_ID = "1050605334506-mm9ee3g377ocq8jrvajnp2t6grbiuscr.apps.googleusercontent.com";
export const COGNITO_USER_POOL_ID = "us-west-2_rjKCiwu0o";
export const COGNITO_APP_CLIENT_ID = "7bkmab3os6nau99k24mb05644p";
export const SENTRY_ORG="swing-education";
export const SWING_SUBS_SENTRY_PROJECT="swing-subs";
export const SWING_SUBS_SENTRY_AUTH_TOKEN = "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";
export const SWING_SUBS_SENTRY_DSN="https://4a17a24598db65536e85d3c603cb9937@o160562.ingest.us.sentry.io/4507131776270336";
export const HELLOSIGN_CLIENT_ID="c78886b90689fe0f55d5ca84725765ec";
export const SAASQUATCH_TENANT_ALIAS="a7jyb2mek1j5d";
export const AUTHENTICATION_COOKIE_DOMAIN = ".swingeducation.com";
