import { IonIcon, IonText } from "@ionic/react";
import { useHistory } from "react-router";
import { FEATURE_FLAGS } from "src/utils/feature-flags";
import { schoolIconSVG } from "swing-assets";
import { Colors, Icon } from "swing-components";

import { ButtonScore, Card, cardHeaderData, Chip } from "~components";
import { createMyRequestsDetailsUrl } from "~pages";
import {
  formatDisplayTimes,
  formatPay,
  generateGoogleMapsLink,
  msg,
  PastCommitmentRequestData,
  UpcomingCommitmentRequestData,
} from "~utils";
import styles from "./CommitmentCard.module.css";

export type CommitmentCardProps = {
  request: UpcomingCommitmentRequestData | PastCommitmentRequestData;
};

export function CommitmentCard(props: CommitmentCardProps) {
  const { request } = props;
  const chipData = cardHeaderData[request.status];

  const requestUrl = createMyRequestsDetailsUrl(request.id);
  const isPast = request.status === "STATUS_COMPLETED";

  const history = useHistory();
  const hasFeedback = "hasFeedback" in request ? request.hasFeedback : false;

  return (
    <Card isBorderRadius isBoxShadow href={requestUrl}>
      <div className={styles["commitment-card-wrapper"]}>
        <div>
          <Chip color={chipData.chipColor} label={chipData.chipLabel} icon={chipData.icon} />
        </div>
        <div className={styles["date-row"]}>
          <span style={{ fontWeight: "var(--swing-font-semibold)" }}>{request.displayDate}</span>
          {!request.isMultiDay && (
            <span style={{ color: Colors.black, fontSize: "12px" }}>
              {formatDisplayTimes(request.displayTime)}
            </span>
          )}
          {!!("hasConflict" in request && request.hasConflict) && (
            <span style={{ color: Colors.red500, fontSize: "12px" }}>
              {msg("COMMITMENT_CARD_CONFLICT")}
            </span>
          )}
        </div>
        <div className={styles["divider"]}></div>
        <div>
          <div className={styles["info-row"]}>
            <div>
              <IonIcon className={`ion-no-margin ${styles["school-icon"]}`} src={schoolIconSVG} />
            </div>
            <div>
              <span>{request.school.name}</span>
              {!isPast && request.school.address && (
                // FIXME: Implement apple maps functionality for app review
                <a
                  className={styles["school-address"]}
                  target="_blank"
                  rel="noreferrer noopener"
                  href={generateGoogleMapsLink(request.school.address)}
                >
                  <IonText
                    className={`${styles["truncate-two-lines"]} ${styles["truncate-one-line"]}`}
                  >
                    {request.school.address.street}
                  </IonText>
                  <IonText
                    className={`${styles["truncate-two-lines"]} ${styles["truncate-one-line"]}`}
                  >
                    {`${request.school.address.city}, ${request.school.address.state} ${request.school.address.zip}`}
                  </IonText>
                </a>
              )}
            </div>
          </div>
          {request.payment && (
            <div className={styles["info-row"]}>
              <Icon name="Monetization" />
              <IonText>{formatPay(request.payment.isHourly, request.payment.payValue)}</IonText>
            </div>
          )}
          {FEATURE_FLAGS.SCHOOL_FEEDBACK && isPast && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {!hasFeedback ? (
                <ButtonScore
                  onClick={() => {
                    history.push(requestUrl);
                  }}
                  fill="outline"
                  label={msg("SCHOOL_FEEDBACK_TITLE")}
                />
              ) : (
                <span
                  style={{
                    fontWeight: "var(--swing-font-semibold)",
                    color: "var(--swing-green500)",
                    display: "flex",
                    gap: "8px",
                  }}
                >
                  <Icon name="Check Circle" color={Colors.green500}></Icon>
                  {msg("SCHOOL_FEEDBACK_REVIEWED_LABEL")}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </Card>
  );
}
