import { jsx as i, jsxs as p } from "react/jsx-runtime";
import { I as c } from "../../../../../Icon-Dm1kHhoN.js";
import "../../../../../AuthProvider-2B-MIORJ.js";
import "@ionic/react";
import t from "../../../../string-table.js";
import "@capacitor/core";
import { IconBlock as m } from "../../../../molecules/IconBlock/IconBlock.js";
import "react";
import "../../../../../react-paginate-Lh5zQzpz.js";
import { Section as l } from "../Section/Section.js";
function E(e) {
  const {
    qualifications: n,
    isSwingHero: a,
    skillBuilderCompletedTasks: s = []
  } = e;
  return /* @__PURE__ */ i(l, {
    title: t("SUB_PROFILE_QUALIFICATIONS_HEADER"),
    children: /* @__PURE__ */ p("div", {
      style: {
        marginTop: "var(--spacing-sm)"
      },
      children: [a && /* @__PURE__ */ i(m, {
        icon: /* @__PURE__ */ i(c, {
          name: "Swing Hero"
        }),
        content: /* @__PURE__ */ i("span", {
          children: `${t("SUB_PROFILE_CONTACT_SWING")} ${t("SUB_PROFILE_CONTACT_HERO")}`
        }),
        hasDivider: !0
      }), n.map((r, o) => /* @__PURE__ */ i("div", {
        children: /* @__PURE__ */ i(m, {
          icon: /* @__PURE__ */ i(c, {
            name: "Verified"
          }),
          content: /* @__PURE__ */ i("span", {
            children: r
          }),
          hasDivider: !0
        })
      }, `${o}-${r}`)), s.length > 0 && s.map((r, o) => /* @__PURE__ */ i("div", {
        children: /* @__PURE__ */ i(m, {
          icon: /* @__PURE__ */ i(c, {
            name: "Lesson"
          }),
          content: /* @__PURE__ */ i("span", {
            children: `${d(r)} (${t("SUB_PROFILE_QUALIFICATIONS_SKILL_BUILDER_SUB_LABEL")})`
          }),
          hasDivider: !0
        })
      }, `${o}-${r}`))]
    })
  });
}
function d(e) {
  return e === "lwc.skill-check/outro" ? "Lead With Confidence" : e.split(".")[0].split("-").map((n) => n.charAt(0).toUpperCase() + n.slice(1)).join(" ");
}
export {
  E as Qualifications,
  d as formatSkillName
};
