import { jsx as o, jsxs as i, Fragment as _ } from "react/jsx-runtime";
import { I as x, g as T } from "../../../Icon-Dm1kHhoN.js";
import { IonBackdrop as A, IonButton as g, IonText as I } from "@ionic/react";
import { useState as c } from "react";
import { Colors as S } from "../../foundations/Colors/Colors.js";
import { u as y, h as R, E as B } from "../../../AuthProvider-2B-MIORJ.js";
import l from "../../string-table.js";
import "../../../react-paginate-Lh5zQzpz.js";
import { LoadingInterstitial as C } from "../LoadingInterstitial/LoadingInterstitial.js";
import '../../../css/Login.css';const k = "_form_wmum0_1", M = "_divider_wmum0_5", D = "_emailInput_wmum0_27", P = "_loadingContainer_wmum0_31", V = "_loginButton_wmum0_48", Y = "_buttonText_wmum0_56", W = "_googleIcon_wmum0_61", n = {
  form: k,
  divider: M,
  emailInput: D,
  loadingContainer: P,
  loginButton: V,
  buttonText: Y,
  googleIcon: W
};
function $(e) {
  const {
    loginWithPasswordPath: a,
    history: f,
    onLoginEmailSubmit: h,
    buildProfile: E
  } = e, {
    signIn: u,
    isAuthenticating: p
  } = y(), [r, L] = c(""), [N, s] = c(), [b, d] = c(!1), w = (t) => {
    L(t), z(t) ? d(!0) : d(!1);
  }, O = async () => {
    const t = await h(r).catch((m) => s(m));
    if (t)
      switch (t) {
        case "COGNITO":
          s(void 0), f.push({
            pathname: a,
            // Explicitly not using `useLocation` as we want to live value vs
            // cached value.
            search: window.location.search,
            state: {
              email: r
            }
          });
          break;
        case "GOOGLE":
          u({
            provider: t.toLowerCase()
          }).catch((m) => {
            s(m);
          });
          break;
      }
  };
  async function G(t) {
    t.preventDefault(), await O();
  }
  async function v(t) {
    t.preventDefault(), await u({
      provider: "google"
    });
  }
  return /* @__PURE__ */ o(R, {
    title: l("LOGIN_EMAIL_ENTRY_LOGIN_MESSAGE"),
    children: p ? /* @__PURE__ */ i(_, {
      children: [/* @__PURE__ */ o(A, {
        visible: !0
      }), /* @__PURE__ */ o("div", {
        className: n.loadingContainer,
        children: /* @__PURE__ */ o(C, {})
      })]
    }) : /* @__PURE__ */ i(_, {
      children: [/* @__PURE__ */ i("form", {
        className: n.form,
        onSubmit: async (t) => await G(t),
        children: [/* @__PURE__ */ o("div", {
          className: n.emailInput,
          children: /* @__PURE__ */ o(B, {
            email: r,
            onEmailInput: (t) => w(t),
            readOnly: !1,
            error: N,
            buildProfile: E
          })
        }), /* @__PURE__ */ i(g, {
          type: "submit",
          size: "default",
          expand: "block",
          className: n.loginButton,
          disabled: !b,
          children: [/* @__PURE__ */ o(I, {
            className: n.buttonText,
            children: l("LOGIN_EMAIL_ENTRY_LOGIN_LABEL_ENTER_PASSWORD")
          }), /* @__PURE__ */ o(x, {
            name: "Arrow Right",
            color: S.white200
          })]
        })]
      }), /* @__PURE__ */ o(j, {
        label: l("LOGIN_EMAIL_ENTRY_LABEL_OR")
      }), /* @__PURE__ */ o("form", {
        className: n.form,
        onSubmit: async (t) => await v(t),
        children: /* @__PURE__ */ i(g, {
          type: "submit",
          size: "default",
          fill: "outline",
          expand: "block",
          className: n.loginButton,
          children: [/* @__PURE__ */ o("img", {
            src: T,
            className: n.googleIcon,
            alt: "Login with Google"
          }), /* @__PURE__ */ o(I, {
            className: n.buttonText,
            children: l("LOGIN_EMAIL_ENTRY_LOGIN_LABEL_CONTINUE_WITH_GOOGLE")
          })]
        })
      })]
    })
  });
}
function j(e) {
  const {
    label: a
  } = e;
  return /* @__PURE__ */ o("div", {
    className: n.divider,
    children: a
  });
}
function z(e) {
  return new RegExp(/.+@.+\..{2,}/).test(e);
}
export {
  $ as Login,
  z as isValidEmail
};
