import { jsx as n, jsxs as s } from "react/jsx-runtime";
import { IonItem as _ } from "@ionic/react";
import { I as m } from "../../../Icon-Dm1kHhoN.js";
import "../../../AuthProvider-2B-MIORJ.js";
import "@capacitor/core";
import "react";
import "../../../react-paginate-Lh5zQzpz.js";
import '../../../css/DataItemView.css';const g = "_icon_7f3w7_54", f = "_label_7f3w7_58", h = "_children_7f3w7_70", i = {
  "data-item": "_data-item_7f3w7_1",
  "data-grid-icon": "_data-grid-icon_7f3w7_6",
  "data-grid-icon-stacked": "_data-grid-icon-stacked_7f3w7_22",
  "data-grid-no-icon": "_data-grid-no-icon_7f3w7_30",
  "data-grid-no-icon-stacked": "_data-grid-no-icon-stacked_7f3w7_46",
  icon: g,
  label: f,
  children: h
};
function v(d) {
  const {
    icon: a,
    iconColor: c,
    label: t,
    lines: e,
    stackLayout: o,
    children: r,
    style: l
  } = d;
  return /* @__PURE__ */ n(_, {
    lines: e || "none",
    className: `${i["data-item"]} ion-no-padding`,
    style: {
      ...l
    },
    children: /* @__PURE__ */ s("div", {
      className: `${a ? i["data-grid-icon"] : i["data-grid-no-icon"]} ${o && a ? i["data-grid-icon-stacked"] : o && !a ? i["data-grid-no-icon-stacked"] : ""}`,
      children: [a ? /* @__PURE__ */ n("div", {
        className: `${i.icon}`,
        children: /* @__PURE__ */ n(m, {
          name: a,
          color: c
        })
      }) : null, /* @__PURE__ */ n("div", {
        className: i.label,
        children: t
      }), /* @__PURE__ */ n("div", {
        className: i.children,
        children: r
      })]
    })
  });
}
export {
  v as DataItemView
};
