import { jsx as o } from "react/jsx-runtime";
import { IonButton as i } from "@ionic/react";
import { Colors as n } from "../../../../foundations/Colors/Colors.js";
import { I as m } from "../../../../../Icon-Dm1kHhoN.js";
import "../../../../../AuthProvider-2B-MIORJ.js";
import "@capacitor/core";
import "react";
import "../../../../../react-paginate-Lh5zQzpz.js";
function g(t) {
  const {
    onClick: r
  } = t;
  return /* @__PURE__ */ o(i, {
    fill: "clear",
    size: "small",
    onClick: r,
    style: {
      margin: 0,
      "--padding-bottom": 0,
      "--padding-top": 0,
      "--padding-start": 0,
      "--padding-end": 0
    },
    children: /* @__PURE__ */ o(m, {
      name: "Edit",
      color: n.blue500
    })
  });
}
export {
  g as ButtonEdit
};
