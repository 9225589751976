import { ActionSheetButtonStyle, ActionSheetPlugin } from "@capacitor/action-sheet";
import { AppLauncherPlugin } from "@capacitor/app-launcher";
import { History } from "history";
import { DateTime } from "luxon";
import { generatePath } from "react-router";

import { createRequestsUrl } from "~pages";
import { msg, OpeningsData, RequestData } from "~utils";

export function centsToDollars(valueInCents: number): string {
  return (valueInCents / 100).toLocaleString("en-US", { style: "currency", currency: "USD" });
}

export function payDisplay(amount: string, suffix = "", seperator = "/") {
  if (suffix == "") {
    return `${amount}${suffix}`;
  } else {
    return `${amount}${seperator}${suffix}`;
  }
}

export function isMultiDay(intervals: RequestData["intervals"]) {
  return intervals.length > 1;
}

export function requestTimeDisplay(data: OpeningsData) {
  return isMultiDay(data.intervals)
    ? `${data.intervals.length} ${msg("REQUEST_TIME_UNIT")}`
    : data.displayTime;
}

const GRACE_PERIOD_CUTOFF_IN_MINS = 30;
const PENALTY_PERIOD_CUTOFF_IN_HRS = 24;

export type CancelPenaltyType =
  | "NO_PENALTY"
  | "PENALTY_REQUEST_STARTED"
  | "PENALTY_REQUEST_NOT_STARTED";

export type CalcCancelPenaltyTypeProps = {
  reqStartTime: string | DateTime;
  reqFillTime: string | DateTime;
  reqCancelTime: string | DateTime;
};

// NOTE: Expects `props` keys to be one of two types:
// 1. a date string in ISO format set to UTC 0
// 2. a DateTime set to UTC
export function calcCancelPenaltyType(props: CalcCancelPenaltyTypeProps): CancelPenaltyType {
  let { reqStartTime, reqFillTime, reqCancelTime } = props;

  if (typeof reqStartTime === "string") {
    reqStartTime = DateTime.fromISO(reqStartTime, { zone: "utc" });
  }

  if (typeof reqFillTime === "string") {
    reqFillTime = DateTime.fromISO(reqFillTime, { zone: "utc" });
  }

  if (typeof reqCancelTime === "string") {
    reqCancelTime = DateTime.fromISO(reqCancelTime, { zone: "utc" });
  }

  const graceThreshold = reqFillTime.plus({
    minutes: GRACE_PERIOD_CUTOFF_IN_MINS,
  });
  const startThreshold = reqStartTime.minus({
    hours: PENALTY_PERIOD_CUTOFF_IN_HRS,
  });
  const isCancelTimeInsideGracePeriod = reqCancelTime <= graceThreshold;
  const isCancelTimeOutsidePenaltyPeriod = reqCancelTime <= startThreshold;
  const isCancelTimeAfterRequestStart = reqCancelTime > reqStartTime;

  if (isCancelTimeAfterRequestStart) {
    return "PENALTY_REQUEST_STARTED";
  }

  if (isCancelTimeInsideGracePeriod || isCancelTimeOutsidePenaltyPeriod) {
    return "NO_PENALTY";
  }

  return "PENALTY_REQUEST_NOT_STARTED";
}

type GenerateGoogleMapsLinkProps = Partial<{
  street: string;
  city: string;
  state: string;
  zip: string;
}>;

export function generateGoogleMapsLink(props: GenerateGoogleMapsLinkProps): string {
  const { street, city, state, zip } = props;

  return `http://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    `${street} ${city}, ${state}, ${zip}`,
  )}`;
}

// based on whether request is hourly
export function formatPay(isHourly: boolean, paymentInCents: number) {
  const dollars = centsToDollars(paymentInCents);
  if (isHourly) {
    return `${payDisplay(dollars, "hr")}`;
  }
  return `${dollars}`;
}

export function maybePluralize(str: string, count: number): string {
  if (count === 1) {
    return `${count} ${str}`;
  } else {
    return `${count} ${str}s`;
  }
}

// Header Navigation
export function generateBackNavigation(history: History) {
  const splitPathName = history.location.pathname.toString().split("/").filter(Boolean);
  splitPathName.pop();
  // to account for no school list page in sub app
  if (splitPathName.includes("school") || splitPathName.includes("skill-builder")) {
    splitPathName.pop();
  }
  return splitPathName.length > 0
    ? history.push(generatePath(`/${splitPathName.join("/")}`))
    : history.push(createRequestsUrl());
}

export const formatDisplayTimes = (times: string) => {
  return times.replace("AM", " am").replace("PM", " pm");
};

export function formatStringArray(items: string[]) {
  return items.join(", ");
}

// TODO: move this into "OpeningsCard" component once created
export function formatGradesAndSubjects(grades: string[], subjects: string[]) {
  // grades - selected
  let g = formatStringArray(grades);
  // subjects - selected
  let s = formatStringArray(subjects);

  // grades - not selected
  if (g.toLowerCase() === "") {
    g = msg("GRADE_NOT_PROVIDED");
  }

  // grades - unknown
  if (g.toLowerCase() === "unknown") {
    g = msg("GRADE_UNKNOWN");
  }

  // subjects - not selected
  if (s.toLowerCase() === "") {
    s = msg("SUBJECT_NOT_PROVIDED");
  }

  // subjects - unknown
  if (s.toLowerCase() === "unknown") {
    s = msg("SUBJECT_UNKNOWN");
  }

  return formatStringArray([g, s]);
}

/**
 * Returns an array of length `n` where each element is the result of calling
 * `fn` with the index of the element.
 *
 * @example
 * const [q1, q2, q3] = numberOf(3,
 *  (index, total) => newQuestion({ label: `Question #${index + 1} of ${total}` })
 * );
 */
export function numberOf<TReturn>(n: number, fn: (index: number, n: number) => TReturn) {
  return Array.from({ length: n }, (_, index) => fn(index, n));
}

export type HandleMapLinkNavigationParams = {
  getPlatform: () => string;
  appLauncher: AppLauncherPlugin;
  actionSheet: ActionSheetPlugin;
  openLinkInApp: (fullUrl: string) => Promise<void>;
  link: string;
  query: string;
};

export async function handleMapLinkNavigation(
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  params: HandleMapLinkNavigationParams,
) {
  const { getPlatform, appLauncher, actionSheet, openLinkInApp, link, query } = params;

  // default href behavior when on web
  if (getPlatform() === "web") {
    return;
  }

  // prevent default when on native app
  event.preventDefault();

  // if Android then check for Google Maps. Fallback to Google Maps in browser
  if (getPlatform() === "android") {
    try {
      const isGoogleMapsAvailable = await appLauncher.canOpenUrl({ url: "comgooglemaps://" });
      if (isGoogleMapsAvailable.value) {
        const isCompleted = await appLauncher.openUrl({
          url: `comgooglemaps://?q=${query}`,
        });
        // if attempt to open Google Maps app fails, 'completed' will be false so open in browser
        if (!isCompleted.completed) {
          return await openLinkInApp(link);
        }
      }
      // if Google Maps app is not available, open in browser
      return await openLinkInApp(link);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Unable to open Google Maps app or website on Android", err);
    }
  }

  // if iOS then check for both Apple Maps and Google Maps. When both available, present prompt otherwise open in one app or the other. Fallback to Google Maps in browser
  if (getPlatform() === "ios") {
    try {
      const isAppleMapsAvailable = await appLauncher.canOpenUrl({ url: "http://maps.apple.com" });
      const isGoogleMapsAvailable = await appLauncher.canOpenUrl({ url: "comgooglemaps://" });

      // when both apps are available, show prompt allowing user to decide which app to open
      if (isAppleMapsAvailable.value && isGoogleMapsAvailable.value) {
        const actionSheetResult = await actionSheet.showActions({
          title: "Open In...",
          options: [
            { title: "Apple Maps" },
            { title: "Google Maps" },
            { title: "Cancel", style: ActionSheetButtonStyle.Cancel },
          ],
        });

        // Apple Maps
        if (actionSheetResult.index === 0) {
          return await appLauncher.openUrl({
            url: `http://maps.apple.com?q=${query}`,
          });
        }
        // Google Maps
        if (actionSheetResult.index === 1) {
          return await appLauncher.openUrl({
            url: `comgooglemaps://?q=${query}`,
          });
        }
        // Cancel
        if (actionSheetResult.index === 2) {
          // just exit function
          return;
        }
      }

      // when only Apple Maps available
      if (isAppleMapsAvailable.value) {
        const isCompleted = await appLauncher.openUrl({
          url: `http://maps.apple.com?q=${query}`,
        });
        // if Apple Maps app fails to open then `completed` will be false so open in browser
        if (!isCompleted.completed) {
          return await openLinkInApp(link);
        }
        return;
      }

      // when only Google Maps available
      if (isGoogleMapsAvailable.value) {
        const isCompleted = await appLauncher.openUrl({
          url: `comgooglemaps://?q=${query}`,
        });
        // if Google Maps app fails to open then `completed` will be false so open in browser
        if (!isCompleted.completed) {
          return await openLinkInApp(link);
        }
        return;
      }

      // when neither Apple Maps or Google Maps apps are available
      if (!isAppleMapsAvailable.value && !isGoogleMapsAvailable.value) {
        // open Google Maps in browser
        return await openLinkInApp(link);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Unable to open Google Maps app or website on iOS", err);
    }
  }
}
