import { jsx as e, jsxs as c } from "react/jsx-runtime";
import "@ionic/react";
import "../../../../../AuthProvider-2B-MIORJ.js";
import s from "../../../../string-table.js";
import "@capacitor/core";
import "react";
import "../../../../../react-paginate-Lh5zQzpz.js";
import { Section as E } from "../Section/Section.js";
import '../../../../../css/SwingExperience.css';const i = "_experienceWrapper_19krx_1", S = "_statWrapper_19krx_10", u = "_flexStart_19krx_17", m = "_spaceBetween_19krx_22", I = "_statCount_19krx_26", x = "_statLabel_19krx_31", n = {
  experienceWrapper: i,
  statWrapper: S,
  flexStart: u,
  spaceBetween: m,
  statCount: I,
  statLabel: x
};
function d(t) {
  const {
    daysWorked: r,
    schoolsWorked: a,
    timesPreferred: _,
    positiveReviews: l
  } = t, p = W(t) === 2;
  return /* @__PURE__ */ e(E, {
    title: s("SUB_PROFILE_SWING_EXPERIENCE_HEADER"),
    children: /* @__PURE__ */ e("div", {
      children: /* @__PURE__ */ c("ul", {
        className: `${n.experienceWrapper} ${p ? n.flexStart : n.spaceBetween}`,
        children: [!!r && /* @__PURE__ */ e(o, {
          label: s("SUB_PROFILE_SWING_EXPERIENCE_DAYS_WORKED"),
          statCount: r
        }), !!a && /* @__PURE__ */ e(o, {
          label: s("SUB_PROFILE_SWING_EXPERIENCE_SCHOOLS_WORKED"),
          statCount: a
        }), !!_ && /* @__PURE__ */ e(o, {
          label: s("SUB_PROFILE_SWING_EXPERIENCE_TIMES_PREFERRED"),
          statCount: _
        }), !!l && /* @__PURE__ */ e(o, {
          label: s("SUB_PROFILE_SWING_EXPERIENCE_POSITIVE_REVIEWS"),
          statCount: l
        })]
      })
    })
  });
}
function R(t) {
  return t.split(" ").map((r, a, _) => a < _.length - 1 ? `${r} <br />` : r).join(" ");
}
function W(t) {
  return Object.values(t).length;
}
function o(t) {
  const {
    label: r,
    statCount: a
  } = t;
  return /* @__PURE__ */ c("li", {
    className: n.statWrapper,
    children: [/* @__PURE__ */ e("span", {
      className: n.statCount,
      children: a
    }), /* @__PURE__ */ e("span", {
      dangerouslySetInnerHTML: {
        __html: R(r)
      },
      className: n.statLabel
    })]
  });
}
export {
  d as SwingExperience
};
