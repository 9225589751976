var s = Object.defineProperty;
var c = (e, t, a) => t in e ? s(e, t, { enumerable: !0, configurable: !0, writable: !0, value: a }) : e[t] = a;
var u = (e, t, a) => c(e, typeof t != "symbol" ? t + "" : t, a);
class g {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(t = {}) {
    // optional for testing purposes only
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    u(this, "authData");
    this.authData = t;
  }
  get provider() {
    var t;
    return (t = this.authData) == null ? void 0 : t.provider;
  }
  get token() {
    var t, a, o, r, i, h, n;
    switch ((t = this.authData) == null ? void 0 : t.provider) {
      case "google":
        return ((o = (a = this.authData) == null ? void 0 : a.authentication) == null ? void 0 : o.idToken) || ((r = this.authData) == null ? void 0 : r["google/token"]);
      case "cognito":
        return ((h = (i = this.authData) == null ? void 0 : i.idToken) == null ? void 0 : h.jwtToken) || ((n = this.authData) == null ? void 0 : n["cognito/token"]);
      default:
        return "";
    }
  }
  get refreshTokenExpiration() {
    var t, a;
    switch ((t = this.authData) == null ? void 0 : t.provider) {
      case "cognito":
        return (a = this.authData) == null ? void 0 : a.refreshTokenExpiration;
      default:
        return;
    }
  }
}
export {
  g as AuthModel
};
