import { RefObject } from "react";
import { LoadingInterstitial } from "swing-components";

import { usePullToRefresh } from "./usePullToRefresh";

type PullToRefreshLoadingProps = {
  elementRef: RefObject<HTMLDivElement>;
  onRefresh: () => void;
};

export function PullToRefreshLoadingIndicator({
  elementRef,
  onRefresh,
}: PullToRefreshLoadingProps) {
  const { isRefreshIndicatorVisible } = usePullToRefresh(elementRef, onRefresh);

  return isRefreshIndicatorVisible ? (
    <div
      style={{
        height: 44, // original height of loading indicator * 0.3
        left: "50%",
        transform: "translateX(-50%) scale(0.3)",
        transformOrigin: "center",
        position: "absolute",
        transition: "transform 0.2s ease-in-out",
      }}
    >
      <LoadingInterstitial />
    </div>
  ) : null;
}
