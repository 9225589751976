import { jsx as e, jsxs as r, Fragment as a } from "react/jsx-runtime";
import f from "../../string-table.js";
import { Colors as t } from "../../foundations/Colors/Colors.js";
import { I as p } from "../../../Icon-Dm1kHhoN.js";
import { Asterisk as c } from "../../atoms/Asterisk/Asterisk.js";
import "../../../AuthProvider-2B-MIORJ.js";
import "@ionic/react";
import "@capacitor/core";
import "react";
import "../../../react-paginate-Lh5zQzpz.js";
function j(m) {
  const {
    id: n,
    label: h,
    value: o,
    stacked: b,
    placeholder: u,
    onClick: i,
    isDisabled: l = !1,
    isRequired: d = !1
  } = m, s = () => /* @__PURE__ */ e("span", {
    id: `${n}-dropdown`,
    style: {
      fontWeight: "var(--swing-font-semibold"
    },
    children: h
  });
  return /* @__PURE__ */ e("div", {
    id: n,
    onClick: i,
    style: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      padding: "16px 0",
      borderBottom: `1px solid ${t.slate200}`,
      cursor: "pointer"
    },
    children: b && o ? /* @__PURE__ */ r(a, {
      children: [/* @__PURE__ */ r("div", {
        children: [/* @__PURE__ */ r("div", {
          children: [/* @__PURE__ */ e(s, {}), d && /* @__PURE__ */ e(c, {})]
        }), /* @__PURE__ */ e("div", {
          children: o
        })]
      }), /* @__PURE__ */ e("button", {
        type: "button",
        disabled: l,
        style: {
          backgroundColor: "transparent"
        },
        children: /* @__PURE__ */ e(p, {
          name: "Chevron Down",
          color: t.blue500
        })
      })]
    }) : /* @__PURE__ */ r(a, {
      children: [/* @__PURE__ */ r("div", {
        children: [/* @__PURE__ */ e(s, {}), d && /* @__PURE__ */ e(c, {})]
      }), /* @__PURE__ */ r("div", {
        style: {
          display: "flex",
          gap: "4px",
          alignItems: "center"
        },
        children: [/* @__PURE__ */ e("span", {
          children: o || u || f("LABEL_SELECT")
        }), /* @__PURE__ */ e("button", {
          type: "button",
          disabled: l,
          onClick: i,
          style: {
            backgroundColor: "transparent"
          },
          children: /* @__PURE__ */ e(p, {
            name: "Chevron Down",
            color: t.blue500
          })
        })]
      })]
    })
  });
}
export {
  j as DropDown
};
